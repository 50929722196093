import { AxiosResponse } from 'axios'
import { useEffect } from 'react'
import { JobCategorySerializeType, NestedCategoryType } from '../type/Common'
import { API_URL_JOB_CATEGORIES } from '../utilities/APIConstant'
import client from '../utilities/Client'
import { createOneTimeRun } from '../utilities/SingleInvokeFuction'
import { createGlobalStage } from './share/GlobalStateHooks'

const [useJobCategoriesState] = createGlobalStage<NestedCategoryType[]>()
const oneTimeRun = createOneTimeRun()
export const useJobCategories = () => {
  const [job_categories, set_job_categories] = useJobCategoriesState()
  useEffect(() => {
    oneTimeRun(() =>
      client.get(API_URL_JOB_CATEGORIES, {}).then((res: AxiosResponse<JobCategorySerializeType>) => {
        set_job_categories(
          res.data.data.map((ser) => ({
            ...ser.attributes,
            name: ser.attributes.name,
            subs: ser.attributes.subs.data.map((ser2) => ({
              ...ser2.attributes,
              name: ser2.attributes.name
            }))
          }))
        )
      })
    )
  }, [])
  return { job_categories }
}
