import { AxiosResponse } from 'axios'
import { useEffect } from 'react'
import { FastJsonList, LocationType } from '../type/Common'
import { API_FETCH_LOCATION } from '../utilities/APIConstant'
import client from '../utilities/Client'
import { createOneTimeRun } from '../utilities/SingleInvokeFuction'
import { createGlobalStage } from './share/GlobalStateHooks'

const [useLocationState] = createGlobalStage<FastJsonList<LocationType>>()
const oneTimeRun = createOneTimeRun()
export const useLocation = () => {
  const [locations, setLocation] = useLocationState()
  useEffect(() => {
    oneTimeRun(() =>
      client.get(API_FETCH_LOCATION, {}).then((response: AxiosResponse<FastJsonList<LocationType>>) => {
        const locations = response.data
        setLocation({
          ...locations,
          data: locations.data.map((item) => ({
            ...item,
            attributes: { ...item.attributes, state: item.attributes.state }
          }))
        })
      })
    )
  }, [])
  return locations
}
